import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/artillery.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 36 Bl. 182-183 <br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: after 1690 (?)<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (209k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Improving Artillery Range and Accuracy By Using the Force of Air </title> {/*Change title*/}
            <meta name="description" content="An English translation of IMPROVING ARTILLERY RANGE BY USING THE FORCE OF AIR by Gottfried Wilhelm Leibniz, from after 1690 (?)" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: IMPROVING ARTILLERY RANGE BY USING THE FORCE OF AIR</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 [<b>LH 36 Bl. 182r</b>]<br /><br />
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A significant improvement in artillery, and in the use of firearms or projectiles, could be achieved by properly employing the force of air, which until now has been used only out of curiosity in wind arquebuses and vacuum machines.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The usefulness of this would consist of being able to shoot much more accurately and further at the same time. For as the force of gunpowder cannot be precisely regulated, because we cannot be sure of its uniformity, and then because gunpowder exerts a great effort all at once, and in all directions, the slightest inequality means that all the force is thrown noticeably in one direction more than the other; and as this inequality is found either inside the cannon or mortar in relation to its barrel or its charge, or outside in terms of the means of levelling and securing it, it is clear that there is a great concurrence of causes, each capable of making the shot less certain, especially since a little play must be left in the cannon without the ball or its bomb having to fill it precisely. This means that when the ball exits the mouth, the line of the shot is very often not quite parallel to the line of the barrel. The recoil and the slightest movement of the cannon or mortar support also cause the gunpowder to produce some irregularity at the moment of the shot,<a name="1" href="#note1"><sup>1</sup></a> which diverts the shot from its [intended] direction.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The ancients had large machines that not only launched arrows but even<a name="3" href="#note3"><sup>3</sup></a> very heavy stones, almost like our bombs; and for this purpose they used large trees bent with cables and jacks, and then suddenly released. These machines had an advantage over our artillery in that they could throw their charge with great accuracy, [<b>LH 36 Bl. 182v</b>] and we can judge this somewhat from a small book by an ancient author that has come down to us.<a name="4" href="#note4"><sup>4</sup></a> But conversely, they were large in size, difficult to handle, and difficult to move around. It took a lot of time or a lot of men to cock them, not to mention other advantages of our cannons.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But machines driven by air have this admirable quality, that the force driving them is everywhere, weighs nothing, and can be handled like gunpowder, and yet it is an equal, uniform, and perfectly determined force which nevertheless does not act all at once or in all directions but in whichever direction we want and by degrees; and we can thus regulate and control them as well as we can the machines of the ancients,<a name="5" href="#note5"><sup>5</sup></a> so that there is hardly any play or recoil. So the main reasons that make the shots from the cannon or mortar inaccurate do not apply here. It is also certain that we can shoot from<a name="6" href="#note6"><sup>6</sup></a> extraordinary distances with the cannon<a name="7" href="#note7"><sup>7</sup></a> by giving it an elevation close to that of 45 degrees, as was experienced not long ago in Italy, where the French tried to harass our forces by this means near Mantua,<a name="8" href="#note8"><sup>8</sup></a> although it is not usually used because the shot is too uncertain. But it is clear that this could be practised advantageously with<a name="9" href="#note9"><sup>9</sup></a> machines driven by air, where the shot would be incomparably more accurate.<a name="10" href="#note10"><sup>10</sup></a> And the new means that have been invented for aiming more precisely, and for better judging distances, would also be employed more usefully there than with ordinary artillery.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But (it will be said) the force of gunpowder has a great advantage over all other forces used: it is always cocked and ready, and no time or effort is needed to assemble the machines in which it is used, since all you have to do is set them up and load them. Whereas machines in the style of the ancients, and those driven by air, require time or human strength to make ready to act. This can be seen in the example of the wind arquebus.<br />
<br />[<b>LH 36 Bl. 183r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I admit that this objection is valid and that this is also what has apparently thus far prevented us from using air in a serious way. But it is also in the solution<a name="11" href="#note11"><sup>11</sup></a> found for this problem that lies the importance of the new method that I am proposing.<a name="12" href="#note12"><sup>12</sup></a> In a word, it is by combining the force of gunpowder with that of air, so that we can cock the machine which contains the air by the shot of gunpowder, and consequently with great speed. And then the air, once put in a position to act, and having received its force from that of the gunpowder, will act with all the regularity of which it is capable when we want to release it by means of some trigger. Thus we will have together the usefulness of the great and rapid force of gunpowder and the great accuracy and regulated operation of air. And this will bring artillery to a higher perfection than has been seen until now. For by shooting much more accurately at greater distances we would drive enemies from the fortifications or positions they occupy without exposing ourselves much, and we would more surely ruin their stores, bake-houses, bridges, galleries, dams and other places that we wanted.<a name="13" href="#note13"><sup>13</sup></a><br />
                <br />[<b>LH 36 Bl. 183v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But there is yet another very considerable utility in this, which is that the weight of cannons and mortars will be reduced by more than half. For as gunpowder brings about great exertions on all sides all at once, and especially backwards, this causes the cannon and the mortar to burst if they are not very strong, and the breech in particular needs to be reinforced, which creates enormous weight. This does not apply here, as the thickness which resists the force of the air when we load the piece is also able to resist the force of the shot when fired. And by this means, as the difficulty of transportation is reduced, operations could be carried out in seasons and places where they are not expected.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> shot, │ and makes it go too high or too low or in a direction │ <var>deleted</var> (M1).<br />
                <a name="note2" href="#2">2.</a> direction. │ ¶ But machines that fire through the exertion of air can │ <var>deleted</var> (M1).<br />
                <a name="note3" href="#3">3.</a> even │ stones of very great weight │ <var>deleted</var> (M1).<br />
                <a name="note4" href="#4">4.</a> Probably an allusion to Philo of Byzantium's <var>Belopoeica</var>.<br />
                <a name="note5" href="#5">5.</a> ancients, │ so that there is no recoil and no play │ <var>deleted</var> (M1).<br />
                <a name="note6" href="#6">6.</a> from │ great distances │ <var>deleted</var> (M1).<br />
                <a name="note7" href="#7">7.</a> cannon │ even at elevation, but this method is rarely used ordinarily because the shot from gunpowder is too uncertain. │ <var>deleted</var> (M1).<br />
                <a name="note8" href="#8">8.</a> Leibniz may be referring to the War of Mantuan Succession (1628-1630).<br />
                <a name="note9" href="#9">9.</a> with │ cannons │ <var>deleted</var> (M1).<br />
                <a name="note10" href="#10">10.</a> accurate. │ ¶ We could then usefully employ other new means invented for aiming accurately, and for passably knowing all of a sudden, at a glance and from a single position, the distances of things. Means [1] that could already have some use in ordinary artillery, but which nevertheless have much less │ [2] less useful now, where all the accuracy that would be employed would be undone and overturned by the irregularity and violence of the effect of the gunpowder. Although I would not like to deny that these same means could still be useful to some extent in the ordinary use of shooting or launching with gunpowder, if they were employed properly │ <var>deleted</var> (M1).<br />
                <a name="note11" href="#11">11.</a> solution │ I have found │ <var>deleted</var> (M1).<br />
                <a name="note12" href="#12">12.</a> proposing │ to bring artillery to a higher perfection. │ <var>deleted</var> (M1).<br />
                <a name="note13" href="#13">13.</a> This sentence is not in M1.<br /><br /><br />
                  &#169; Lloyd Strickland 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
