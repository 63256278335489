import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/steno.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 1, 4, 7 Bl. 25<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: after 5 December 1686<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (215k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>An Anecdote about the Conversion of Steno</title> {/*Change title*/}
            <meta name="description" content="An English translation of AN ANECDOTE ABOUT THE CONVERSION OF STENO by Gottfried Wilhelm Leibniz, from after 5 December 1686" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: AN ANECDOTE ABOUT THE CONVERSION OF STENO</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 [<b>LH 1, 4, 7 Bl. 25r</b>]<br /><br />
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The late Mr. Steno, titular bishop of Titiopolis, <var>in partibus infidelium</var>,<a name="1" href="#note1"><sup>1</sup></a> and apostolic vicar (as they call it) in the lands of Lower Saxony, said that he owed his conversion to an English lady in Florence. One day, he went to see her, and she said to him, &quot;Mr. Steno, don't you want to finally become Catholic?&quot; &quot;No, madam&quot;, he said, &quot;I do not have that intention.&quot; She replied in a tone that touched him: &quot;I am sure God will convert you: I ask you to seek out my children's tutor, he will tell you more.&quot; So, having gone down into the courtyard to find him, the lady, looking out of the window, shouted to him: &quot;Sir, don't go that way, go this way&quot;. This voice seemed to him to come from heaven to warn him to leave the path of perdition and take the path of salvation, just as St. Augustine heard his &quot;Tolle, lege&quot;<a name="2" href="#note2"><sup>2</sup></a> from a neighbouring house. This struck [<b>LH 1, 4, 7 Bl. 25v</b>] our good Mr. Steno so deeply<a name="3" href="#note3"><sup>3</sup></a> and put him in such disarray that the tutor thought he was ill. The tutor asked him what was wrong. &quot;Nothing,&quot; he said, &quot;but I am now resolved to become Catholic.&quot; And he became one shortly after.<a name="4" href="#note4"><sup>4</sup></a>

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> &quot;In the lands of unbelievers”.&quot;<br />
                <a name="note2" href="#2">2.</a> &quot;Pick it up, read it&quot;. Leibniz is alluding to St. Augustine's conversion as detailed in <var>Confessions</var> XII: &quot;I was saying these things and weeping in the most bitter contrition of my heart, when suddenly I heard the voice of a boy or a girl—I know not which—coming from the neighboring house, chanting over and over again, 'Pick it up, read it; pick it up, read it.' Immediately I ceased weeping and began most earnestly to think whether it was usual for children in some kind of game to sing such a song, but I could not remember ever having heard the like. So, damming the torrent of my tears, I got to my feet, for I could not but think that this was a divine command to open the Bible and read the first passage I should light upon.&quot; English translation from Augustine, <var>Confessions and Enchiridion</var>, ed. and trans. Albert Cook Outler (Louisville: Westminster John Knox Press, 1955), 175-176.<br />
                <a name="note3" href="#3">3.</a> deeply │ that this tutor thought he was ill │ <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> Steno converted to Catholicism on 4 November 1667.
                <br /><br /><br />
                  &#169; Lloyd Strickland 2023
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
