import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/reunionefforts.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 587-588<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: autumn 1683 (?)<br /><br />
		Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (100k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Unnamed Correspondent about Reunion Efforts (autumn 1683 (?))</title>
            <meta name="description" content="An English translation of a LETTER TO UNNAMED CORRESPONDENT ABOUT REUNION EFFORTS by Gottfried Wilhelm Leibniz, from autumn 1683 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO UNNAMED CORRESPONDENT ABOUT REUNION EFFORTS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 3, 587</b>]<br /><br />
                <center>R.</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There was for a while with us the Bishop of Tina, who also spent time with your theologians in Dresden regarding the matter of reconciling religions, and individually with Carpzov. In this I see that he conducts himself cautiously, as he does not propose, offer, or suggest anything of his own. Instead, he merely [<b>A I 3, 588</b>] collects and discusses the opinions and judgements of the various Protestants with whom he has dealt, and frames them so that they may be refined, moderated, and softened. In this way, he easily safeguards himself, for he proposes different things in different places, not as his own thoughts but as those of others, such as they are. Meanwhile, of the many scattered ideas and drafts presented here and there from one side or the other and which often conflict with each other (as of course they have come from various authors), he does not acknowledge a single one as his own. By this strategy, he ensures that his plan can neither be easily refuted by our people nor censured by his own. He obtained a declaration from our theologians, with which he claims to be very satisfied. I would like to know what your people think. It is said that someone among your people is preparing a refutation of this endeavour; I would like to know if this is true and which draft he has undertaken to refute, as he should be careful not to fight shadows.<a name="1" href="#note1"><sup>1</sup></a> For I have seen various drafts, but all are very different from each other. When asked about this disagreement, the bishop responded that he is not proposing his own opinions but is trying to collect and temper those of others, and therefore it is not surprising if they often conflict.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> &quot;aërem verberet&quot;, literally lash the air.<br /><br /><br />
                        &#169; Lloyd Strickland 2023<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
