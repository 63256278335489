import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/quesnel1.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LBr 749 Bl. 9<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 8 February 1712 (?)<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (92k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Source of Springs</title> {/*Change title*/}
            <meta name="description" content="An English translation of a letter to PASNIER QUESNEL by Gottfried Wilhelm Leibniz, from 8 February 1712 (?)" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO PASQUIER QUESNEL</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 [<b>LBr 749 Bl. 9r</b>]<br /><br />
                

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hanover, 8 February 1712&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;despatched&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Mr. Quesnel<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir,<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although we may not always be of the same view on certain speculative questions, I am nevertheless so assured of your insight and fairness that I would have been very pleased to submit my <var>Theodicy</var> to your judgement before its publication, if it could have been done conveniently and I had thought you would be willing to undertake such an examination.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now that it is printed, you may read it with more ease and at your leisure, should you deem it worthwhile, which is why I take the liberty of sending it to you. And if, sooner or later, you would be willing to share your reflections on it with me, you would [<b>LBr 749 Bl. 9v</b>] undoubtedly find that I am someone who benefits from the insights of learned individuals.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is said that the Bishop of Meaux has recently shed new light on the case of Jansen. For my part, I would like to see a capable person from among those well-versed in his Augustinus produce a brief, systematic, and clear summary of it. That would be the best way to fully reveal the sentiments of this great man. A comparison with St. Augustine would not be out of place, and such a parallel could be composed almost entirely from that father's own words.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have found Mr. Arnauld's letters, and I will have them copied.


                <br /><br /><br />
                  &#169; Lloyd Strickland 2025
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
