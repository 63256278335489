import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Container, Navbar, Nav, FormControl, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Pages from './pages';
import * as Images from './images';
import ScrollToTop from './ScrollToTop';
import TwitterFollowButton from './TwitterFollowButton'; 
import './App.css';
import { Helmet } from 'react-helmet';
import NotFound from './pages/notfound.jsx';

function App() {

  function generateRoutes() {
    const routes = Object.keys(Pages).map(page => {
      const Component = Pages[page];
      return <Route key={page} path={`/${page}`} element={<Component />} />;
    });
  
    routes.push(<Route key="not-found" path="*" element={<NotFound />} />);
    
    return routes;
  }

  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef();
 
  const handleSubmit = (event) => {
    event.preventDefault();
    const keywords = encodeURIComponent(searchInput);
    window.location.href = `/search?keywords=${keywords}`;
  };
    
  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    if (input) {
      const filteredTranslations = translations.filter(translation =>
        (translation.title + translation.italics + translation.dateWritten).toLowerCase().includes(input.toLowerCase())
      );
      const filteredBooks = books.filter(book =>
        book.title.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredResults([...filteredTranslations, ...filteredBooks]);
      setDropdownVisible(true);
    } else {
      setFilteredResults([]);
      setDropdownVisible(false);
    }
  };
   
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownItemClick = () => {
    setDropdownVisible(false);
    setSearchInput('');
    setFilteredResults([]);
  };
  
  const [translations, setTranslations] = useState([]);
  const latestTranslations = translations.filter(translation => translation.new === 'yes');

  const groupTranslationsByDecade = (translations, startYear, interval) => {
    const groupedTranslations = { "Unknown Date": [] };
    translations.forEach(translation => {
      const match = translation.dateWritten.match(/(\d{4})/);
      if (match) {
        const year = parseInt(match[1], 10);
        const period = `${Math.floor((year - startYear) / interval) * interval + startYear}-${Math.floor((year - startYear) / interval) * interval + startYear + interval - 1}`;
        if (!groupedTranslations[period]) {
          groupedTranslations[period] = [];
        }
        groupedTranslations[period].push(translation);
      } else {
        groupedTranslations["Unknown Date"].push(translation);
      }
    });
    return groupedTranslations;
  };
  
  const essaysAccordionRefs = useRef([]);
  const lettersAccordionRefs = useRef([]);
  const textsAccordionRefs = useRef([]);

  const [openEssays, setOpenEssays] = useState([]);
  const [openLetters, setOpenLetters] = useState([]);
  const [openTexts, setOpenTexts] = useState([]);
  
  const handleAccordionHeaderClick = (event, index, setOpenAccordions, openAccordions, accordionRefs) => {
    event.preventDefault();

    const isOpen = openAccordions.includes(index);

    if (!isOpen) {
      closeOtherAccordions(index, setOpenAccordions);

      setTimeout(() => {
        const headerRect = accordionRefs.current[index].getBoundingClientRect();
        const isInViewport = headerRect.top >= 0 && headerRect.bottom <= window.innerHeight;

        if (!isInViewport) {
          accordionRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 330);
    }
  };
  
  const closeOtherAccordions = (index, setOpenAccordions) => {
    const updatedOpenAccordions = [index];
    setOpenAccordions(updatedOpenAccordions);
  };
  
  const renderTranslations = (groupedTranslations, accordionRefs, setOpenAccordions, openAccordions) => {
    return (
      <Accordion defaultActiveKey="0">
        {Object.keys(groupedTranslations).map((period, periodIndex) => (
          <Accordion.Item eventKey={period} key={period}>
            <Accordion.Header ref={ref => (accordionRefs.current[periodIndex] = ref)} onClick={(event) => handleAccordionHeaderClick(event, periodIndex, setOpenAccordions, openAccordions, accordionRefs)}>{period}</Accordion.Header>
            <Accordion.Body style={{ backgroundImage: `url(${Images.newbg})` }}>
              <Row>
                {groupedTranslations[period].map((translation, index) => (
                  <Col key={index} xs={12} md={12} xl={4} className='custom-col' style={{marginBottom: '20px'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                      <Row>
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: '25px' }}>
                            <span className="circle">{index + 1}</span>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <Link to={translation.page}>
                              <h6 className="textTitle" style={{ margin: 0 }}>{translation.title}<i>{translation.italics}</i></h6>
                            </Link>
                            <p style={{ color: 'grey', margin: 0, marginBottom: '17px' }}>{translation.dateWritten}</p>
                            <hr style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 75px)', margin: 0 }}/>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                ))}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };
  
  useEffect(() => {
    const TranslationsList = [
      // Add more translations as needed, add them in chronological order
      { title: 'Chinese Windmill', dateWritten: 'date unknown', type: 'essay', page: 'windmill' },
      { title: 'Morning Thoughts', dateWritten: 'date unknown', type: 'essay', page: 'morning', new: 'yes' },
      { title: 'Society of Brotherly Love', dateWritten: '1669 (?)', type: 'essay', page: 'brotherlylove' },
      { title: 'On the Incarnation of God, or, On the Hypostatic Union', dateWritten: '1669 - 1670(?)', type: 'essay', page: 'hypostatic' },
      { title: 'Leibniz to Magnus Wedderkopf', dateWritten: 'May 1671', type: 'letter', page: 'wedderkopf' },
      { title: 'Trinity. Mind.', dateWritten: 'spring - autumn 1671', type: 'essay', page: 'trinity' },
      { title: 'The Philosopher\'s Confession', dateWritten: 'autumn 1672 - winter 1672/3?', type: 'essay', page: 'confession' },
      { title: 'On the Powers of 0', dateWritten: 'end 1672 - spring 1673 (?)', type: 'essay', page: 'zero' },
      { title: 'The Quadrature of the Circle by a Rational Progression', dateWritten: '10 September - October 1674', type: 'essay', page: 'rationalprogression' }, 
      { title: 'Arithmetic Quadrature of the Circle', dateWritten: 'autumn 1674 - September 1676', type: 'essay', page: 'circle' },
      { title: 'Leibniz to Edme Mariotte', dateWritten: 'October 1674', type: 'letter', page: 'mariotte' },
      { title: 'On Polygamy', dateWritten: '4 Oct. 1675', type: 'essay', page: 'polygamy' },
      { title: 'Leibniz to Nicolas Malebranche', dateWritten: 'first half of 1676', type: 'letter', page: 'malebranche1676'},
      { title: 'Notes on Henry More\'s ', italics: 'Enchiridion Ethicum', dateWritten: 'spring - summer 1676?', type: 'essay', page: 'more' },
      { title: 'On Converging Series', dateWritten: 'June 1676 (?)', type: 'essay', page: 'converging' },
      { title: 'That a Most Perfect Being Exists', dateWritten: '18-21 November (?) 1676', type: 'essay', page: 'perfectbeing' },
      { title: 'Notes on Metaphysics', dateWritten: 'Dec. 1676', type: 'essay', page: 'metaphysics' },
      { title: 'Notes on the Walenburch\'s ', italics: 'A General Treatise on the Controversies of Faith', dateWritten: 'January - April 1677 (?)', type: 'essay', page: 'walenburch' },
      { title: 'The True Method', dateWritten: 'first half of 1677 (?)', type: 'essay', page: 'truemethod' },
      { title: 'Ludolph and Others Approaching the Quadrature of the Circle', dateWritten: '1677', type: 'essay', page: 'ludolph' },
      { title: 'Cartesianism, the Antechamber of the True Philosophy', dateWritten: '1677?', type: 'essay', page: 'cartesianism' },
      { title: 'Demonstration That God Understands All Possibles', dateWritten: '1677?', type: 'essay', page: 'demonstration' },
      { title: 'Existence', dateWritten: '1677?', type: 'essay', page: 'existence' },
      { title: 'On Indifference of Equilibrium', dateWritten: '1677 (?)', type: 'essay', page: 'equilibrium' },
      { title: 'On the Necessity of Choosing the Best', dateWritten: '1677?', type: 'essay', page: 'choosingbest' },
      { title: 'That Not All Possibles Attain Existence', dateWritten: '1677', type: 'essay', page: 'possibles' },
      { title: 'On the Obligation to Believe', dateWritten: '1st half of 1677 (?)', type: 'essay', page: 'obligation' },
      { title: 'Memorial Medal of Quadrature and Arithmetic Machine', dateWritten: '1677 – 1682 (?)', type: 'essay', page: 'quadraturemedal' },
      { title: 'Notes On Henry More\'s ', italics: 'The Immortality of The Soul', dateWritten: 'Jan. 1677 - Jan. 1678 (?)', type: 'essay', page: 'henrymoresoul' },
      { title: 'The Distinction Between Mind And Body', dateWritten: 'early 1677 - early 1678?', type: 'essay', page: 'distinction' },
      { title: 'Importunate Reflections on Human Misery', dateWritten: '1677-1716?', type: 'essay', page: 'importunate' },
      { title: 'How the Soul Acts on the Body', dateWritten: 'early 1677 - early 1678?', type: 'essay', page: 'soulactsbody' },
      { title: 'The Axiom: Nothing is in the Intellect That Was Not in the Senses', dateWritten: 'October 1677 – December 1678 (?)', type: 'essay', page: 'axiom' },
      { title: 'On Distinct Perception', dateWritten: 'Oct. 1677 - Dec. 1678 (?)', type: 'essay', page: 'distinctperception' },
      { title: 'On Spiritual Exercises', dateWritten: 'November 1677', type: 'essay', page: 'spiritualexercises' },
      { title: 'On Finding the Dimensions of Figures', dateWritten: 'c. 1678', type: 'essay', page: 'dimensions', new: 'yes' },
      { title: 'The Source of Springs', dateWritten: '1678 (?)', type: 'essay', page: 'springs' },
      { title: 'A New Observation about the Way of Testing Whether a Number is Prime', dateWritten: 'February 1678', type: 'essay', page: 'primetest' },
      { title: 'Leibniz to Vincent Placcius', dateWritten: '4/14 February 1678', type: 'letter', page: 'placcius1678'},
      { title: 'On Proving Divine Existence', dateWritten: '1678-1679 (?)', type: 'essay', page: 'divineexistence' },
      { title: 'The Views of Socrates Opposed to the New Stoics and Epicureans', dateWritten: '1678 - 1680 (?)', type: 'essay', page: 'socratesviews' },
      { title: 'Analysis Of Abbé Lanion\'s ', italics: 'Meditations on Metaphysics', dateWritten: 'summer 1678 - winter 1680/1681?', type: 'essay', page: 'lanionmetaphysics' },
      { title: 'On Natural Law', dateWritten: 'summer 1678 - winter 1680/1', type: 'essay', page: 'naturallaw' },
      { title: 'Leibniz to Pierre Daniel Huet', dateWritten: '18/28 October 1678', type: 'letter', page: 'huet' },
      { title: 'Leibniz to Jean Gallois', dateWritten: '19 Dec. 1678', type: 'letter', page: 'gallois' },
      { title: 'Leibniz to ???: Letter on Cartesianism', dateWritten: '1679', type: 'letter', page: 'cartesianism1679' },
      { title: 'Algebra and Arithmetic', dateWritten: '1679 (?)', type: 'essay', page: 'algebraarithmetic' },
      { title: 'The Physical Origin of Circumcision', dateWritten: '1679 - 1685 (?))', type: 'essay', page: 'circumcision' },
      { title: 'On an Instrument or Great Art of Thinking', dateWritten: 'March - April 1679 (?)', type: 'essay', page: 'instrument' },
      { title: 'Leibniz to Duke Johann Friedrich', dateWritten: 'autumn 1679', type: 'letter', page: 'johannf' },
      { title: 'Leibniz to Christian Philipp', dateWritten: 'early Dec. 1679', type: 'letter', page: 'philipp1679' },
      { title: 'My Fire Cart', dateWritten: 'c. 1680', type: 'essay', page: 'firecart' },
      { title: 'Plan for a Military Manufacturing Process', dateWritten: 'c. 1680', type: 'essay', page: 'military' },
      { title: 'On Controversies', dateWritten: '1680', type: 'essay', page: 'controversies' },
      { title: 'On Public Happiness', dateWritten: '1680?', type: 'essay', page: 'publichappiness' },
      { title: 'On Freedom and Grace', dateWritten: 'summer 1680 - summer 1684?', type: 'essay', page: 'freedomgrace' },
      { title: 'On Necessity and Contingency', dateWritten: 'summer 1680 - summer 1684?', type: 'essay', page: 'necessitycontingency' },
      { title: 'On Quadrature', dateWritten: '1681 (?)', type: 'essay', page: 'onquadrature' },
      { title: 'Leibniz to Christian Philipp', dateWritten: '11/21 Mar. 1681', type: 'letter', page: 'philipp1681' },
      { title: 'Leibniz to Landgrave Ernst von Hessen-Rheinfels', dateWritten: 'April 1681', type: 'letter', page: 'landgrave1681' },
      { title: 'On The True Proportion of The Circle to The Circumscribed Square, Expressed in Rational Numbers by Gottfried Wilhelm Leibniz', dateWritten: 'November/December 1681', type: 'essay', page: 'trueproportion' },
      { title: 'Leibniz to Landgrave Ernst von Hessen-Rheinfels', dateWritten: '23 Oct./3 Nov. 1682', type: 'letter', page: 'landgrave1682' },
      { title: 'Essay on the Number of Men', dateWritten: '1683 (?)', type: 'essay', page: 'numbermen' },
      { title: 'On Consciousness and Memory', dateWritten: '1683-5(?)', type: 'essay', page: 'consciousness' },
      { title: 'On What Is Absurd, False, and Problematic In Descartes', dateWritten: '1683-1685 (?)', type: 'essay', page: 'descartes' },
      { title: 'On the Animal\'s Soul', dateWritten: '1683 - 1685?', type: 'essay', page: 'animalsoul' },
      { title: 'Remarks on Erhard Weigel\'s ', italics: 'Tetractys', dateWritten: 'first half of 1683 (?)', type: 'essay', page: 'tetractys' },
      { title: 'Remarks on Mr Jurieu\'s book against the Bishop of Condom, now the Bishop of Meaux', dateWritten: 'April 1683 - May 1684', type: 'essay', page: 'jurieu' },
      { title: 'Leibniz to Christoph de Rojas y Spinola', dateWritten: 'mid- (?) April 1683', type: 'letter', page: 'spinola', new: 'yes' },
      { title: 'On the Dogma of the Trinity in the Fathers and the Scholastics', dateWritten: '1683 - spring 1698', type: 'essay', page: 'trinityfathers' },
      { title: 'Divisions', dateWritten: 'summer 1683 - winter 1685/6 (?)', type: 'essay', page: 'divisions' },
      { title: 'Pleasure. An Observation Against Descartes', dateWritten: 'summer 1683 - winter 1685/1686?', type: 'essay', page: 'pleasure' },
      { title: 'Leibniz to Unnamed Correspondent about Reunion Efforts', dateWritten: 'autumn 1683 (?)', type: 'letter', page: 'reunionefforts' },
      { title: 'Extract From a Letter From Mr. Arnauld to Landgrave Ernst, on Leibniz\'s Quadrature of the Circle', dateWritten: '23 October 1683', type: 'essay', page: 'arnauld-quadrature' },
      { title: 'Leibniz to Landgrave Ernst von Hesse-Rheinfels', dateWritten: '1/11 January 1684', type: 'letter', page: 'landgrave1684' },
      { title: 'On the Cartesian Method of Doubt', dateWritten: '1684 - 1698 (?)', type: 'essay', page: 'cartesiandoubt' },
      { title: 'Notes and comments on Brueys\' ', italics: 'Examination of the Reasons Which Gave Rise to the Separation of Protestants', dateWritten: 'late February - first half of March 1684', type: 'essay', page: 'brueys' },
      { title: 'Notes On Malebranche\'s ', italics: 'Treatise on Nature and Grace', dateWritten: 'May 1684 - end 1684?', type: 'essay', page: 'notesonmalebranche' },
      { title: 'Leibniz to Ehrenfried Walter von Tschirnhaus', dateWritten: '17 Oct. 1684', type: 'letter', page: 'tschirnhaus' },
      { title: 'Leibniz to Landgrave Ernst von Hessen-Rheinfels', dateWritten: '29 Dec. 1684', type: 'letter', page: 'landgrave1684-2' },
      { title: 'Leibniz to Veit Ludwig von Seckendorff', dateWritten: '29 Dec. 1684/8 Jan. 1685', type: 'letter', page: 'veit' },
      { title: 'On the Commandment of Charity, Schism, and the Principles of the Church', dateWritten: '1685 (?)', type: 'essay', page: 'commandment' },
      { title: 'Brief Preparatory Remarks in Relation to Apologetic Works', dateWritten: '1685', type: 'essay', page: 'preparatory' },
      { title: 'Landgrave Ernst von Hessen Rheinfels: Remarks on the Person and Passions of the otherwise so Famous and Very Learned and Able Mr. Abbé Antoine Arnauld', dateWritten: '23 March 1685', type: 'essay', page: 'onarnauld' },
      { title: 'Review of Jacques Ozanam\'s ', italics: 'La géométrie pratique', dateWritten: 'October 1685', type: 'essay', page: 'ozanam' },
      { title: 'Definitions', dateWritten: '1685-1696?', type: 'essay', page: 'definitions' },
      { title: 'Theological Propositions', dateWritten: 'autumn 1685 - spring 1686 (?)', type: 'essay', page: 'propositions' },
      { title: 'Leibniz to Landgrave Ernst von Hessen-Rheinfels', dateWritten: '16/26 November 1685', type: 'letter', page: 'landgrave1685' },
      { title: 'On Rejecting the Particular Will of God', dateWritten: 'winter 1685/1686 (?)', type: 'essay', page: 'particularwill' },
      { title: 'Simon Foucher to Leibniz', dateWritten: 'end 1685', type: 'letter', page: 'foucher1685' },
      { title: 'Reckoning Penny Abacus', dateWritten: '1686 (?)', type: 'essay', page: 'abacus' },
      { title: 'Leibniz to Landgrave Ernst von Hessen-Rheinfels', dateWritten: '1/11 February 1686', type: 'letter', page: 'landgrave1686' },
      { title: 'A Vindication of Divine Justice and Human Freedom', dateWritten: 'early 1686 (?)', type: 'essay', page: 'vindication' },
      { title: 'On The General Relation of All Things', dateWritten: 'Apr. - Oct. 1686 (?)', type: 'essay', page: 'relation' },
      { title: 'Plus Ultra', dateWritten: 'April - October 1686 (?)', type: 'essay', page: 'plusultra' },
      { title: 'Leibniz to Simon Foucher', dateWritten: '2 Sept. 1686', type: 'letter', page: 'foucher1686' },
      { title: 'An Anecdote about the Conversion of Steno', dateWritten: 'after 5 December 1686', type: 'essay', page: 'steno', new: 'yes' },
      { title: 'Leibniz to Landgrave Ernst von Hessen-Rheinfels', dateWritten: '9 (?) January 1687 (?)', type: 'letter', page: 'landgrave1687' },
      { title: 'On Defending Against Dispersing of the Soul', dateWritten: 'mid-January 1687 (?)', type: 'essay', page: 'dispersing' },
      { title: 'Leibniz to Simon Foucher', dateWritten: 'July 1687', type: 'letter', page: 'foucher1687' },
      { title: 'Account of Two Seventeenth-Century Jews who Wrote about Religion', dateWritten: '1688 - 1695 (?)', type: 'essay', page: 'account' },
      { title: 'On Symbols and the Characteristic Art', dateWritten: 'summer 1688 (?)', type: 'essay', page: 'symbol' },
      { title: 'On the Reality of Accidents', dateWritten: 'September - December 1688 (?)', type: 'essay', page: 'accidents' },
      { title: 'Notes and Comments on François Bernier\'s “A New Division of the Earth according to the Different Species or Generations of Men”', dateWritten: 'c. 1689-1690', type: 'essay', page: 'bernier', new: 'yes' },
      { title: 'Body Is Not a Substance', dateWritten: 'Mar. 1689 - Mar. 1690 (?)', type: 'essay', page: 'bodysubstance' },
      { title: 'On the Cessation of Distinct Thoughts Without the Extinction of the Soul', dateWritten: 'Mar. 1689 - Mar. 1690 (?)', type: 'essay', page: 'cessation' },
      { title: 'On the System of Occasional Causes', dateWritten: 'Mar. 1689 - Mar. 1690 (?)', type: 'essay', page: 'causes' },
      { title: 'The Origin of Contingent Truths', dateWritten: 'summer 1689?', type: 'essay', page: 'contingenttruths' },
      { title: 'Leibniz to Antonio Alberti (?)', dateWritten: 'mid November - early December 1689', type: 'letter', page: 'alberti' },
      { title: 'Observation of Certain Superstitions of the Slavs', dateWritten: '1690 (?)', type: 'essay', page: 'superstitions' },
      { title: '24 Metaphysical Theses', dateWritten: '1690s (?)', type: 'essay', page: 'theses' },
      { title: 'Discourse on Noble Sentiments', dateWritten: 'after 1690?', type: 'essay', page: 'noble' },
      { title: 'Notes On Isaac Papin\'s ', italics: 'The Vanity of the Sciences', dateWritten: 'after 1690?', type: 'essay', page: 'papin' },
      { title: 'Improving Artillery Range and Accuracy By Using the Force of Air', dateWritten: 'after 1690 (?)', type: 'essay', page: 'artillery' },
      { title: 'On Studious People', dateWritten: 'after June 1690', type: 'essay', page: 'studious' },
      { title: 'Protogaea §§1, 2, 6, 13, 14, 20, 25, 26, 35 & 39', dateWritten: '1690-91', type: 'essay', page: 'protogaea' },
      { title: 'On Religious Peace', dateWritten: 'c. 1691', type: 'essay', page: 'religiouspeace' },
      { title: 'Leibniz: Whether the Essence of Body Consists in Extension', dateWritten: '18 June 1691', page: 'essence-slt', exclude: 'yes'},
      { title: 'Notes on Denis Petau\'s ', italics: 'Theological Dogmas ', dateWritten: '1691 - 1695 ?', type: 'essay', page: 'dogmas' },
      { title: 'On Mr. Bayle\'s', italics: ' Dictionary', dateWritten: '1692', type: 'essay', page: 'onbayle' },
      { title: 'On Reason and Divination', dateWritten: 'after 1692', type: 'essay', page: 'divination' },
      { title: 'Leibniz to Duchess Sophie', dateWritten: '5 July 1692', type: 'letter', page: 'sophie1692' },
      { title: 'Leibniz to Michelangelo Fardella', dateWritten: 'mid-Oct. 1692', type: 'letter', page: 'fardella1692' },
      { title: 'Reading Notes on Nicolas Aubin\'s', italics: ' Histoire des diables de Loudun', dateWritten: '1693', type: 'essay', page: 'diables' },
      { title: 'Aurora, or, the Foundations of the General Science, from the Divine Light to Human Happiness', dateWritten: 'c. 1693', type: 'essay', page: 'aurorascience' },
      { title: 'On Mary Stuart and Popess Joan', dateWritten: 'not before 1693', type: 'essay', page: 'mary' },
      { title: 'On Two Systems of the Sciences', dateWritten: 'c. 1693 (?))', type: 'essay', page: 'sciences' },
      { title: 'Protogaea [journal article]', dateWritten: 'January 1693', type: 'essay', page: 'protogaeaarticle' },
      { title: 'On Horneius\'', italics: '  Disputationum theologicarum', dateWritten: 'after 6 April 1693', type: 'essay', page: 'horneius' },
      { title: 'The Three Degrees of Strength in Opinions', dateWritten: 'May 1693 (?)', type: 'essay', page: 'opinions' },
      { title: 'Leibniz to Simon Foucher', dateWritten: 'end of June 1693', type: 'letter', page: 'foucher1693' },
      { title: 'On the Goal of the Sciences', dateWritten: 'after 9/19 October 1693', type: 'essay', page: 'goalsciences' },
      { title: 'Reading Notes on', italics: '  Verhandeling van de Helle', dateWritten: '1694', type: 'essay', page: 'notes1694' },
      { title: 'On the Ignorant and the Able', dateWritten: 'c. 1694', type: 'essay', page: 'ignorant' },
      { title: 'The Essence of Body', dateWritten: 'c. 1694 (?)', type: 'essay', page: 'essenceofbody' },
      { title: 'Souls', dateWritten: 'c. 1694-1695', type: 'essay', page: 'souls', new: 'yes' },
      { title: 'On Man, Beatitude, God and Christ', dateWritten: '1694-1696 (?)', type: 'essay', page: 'beatitude' },
      { title: 'On Progress to Infinity', dateWritten: '1694-1696 (?)', type: 'essay', page: 'progressinfinity' },
      { title: 'On Sensibility', dateWritten: '1694-1696 (?)', type: 'essay', page: 'sensibility', new: 'yes' },
      { title: 'Binary Division', dateWritten: 'second half of 1694', type: 'essay', page: 'division' },
      { title: 'On the Way of Perfecting Medicine', dateWritten: '26 July 1694', type: 'essay', page: 'medicine' },
      { title: 'Leibniz to (and from) Lorenz Hertel', dateWritten: 'July 1694 - Jan. 1695', type: 'letter', page: 'hertel' },
      { title: 'Leibniz to Electress Sophie', dateWritten: '6/16 Dec. 1694', type: 'letter', page: 'sophie1694' },
      { title: 'The Beginning of the Institutions of Perpetual Right', dateWritten: '1695', type: 'essay', page: 'perpetualright' },
      { title: 'On the Duodecimal Progression', dateWritten: '1695', type: 'essay', page: 'duodecimal' },
      { title: 'Refuting Opinions about the Universe by Consideration of the Final Cause', dateWritten: 'c. 1695', type: 'essay', page: 'refuting' },
      { title: 'Review of Leibniz\'s', italics: '  Codex Juris Gentium Diplomaticus', dateWritten: '10 January 1695', type: 'essay', page: 'codex' },
      { title: 'My Arithmetic Machine', dateWritten: 'April 1695', type: 'essay', page: 'machine' },
      { title: 'Leibniz to the Marquis l\'Hospital', dateWritten: '14/24 June 1695', type: 'letter', page: 'hospital1695' },
      { title: 'Leibniz to Simon Foucher', dateWritten: '5/15 July 1695', type: 'letter', page: 'foucher1695' },
      { title: 'Leibniz to the Marquis l\'Hospital', dateWritten: '12/22 July 1695', type: 'letter', page: 'hospital1695-2' },
      { title: 'Theodicy', dateWritten: 'December 1695', type: 'essay', page: 'theodicaea' },
      { title: 'Double Infinity in Pascal And Monad', dateWritten: 'c. 1696', type: 'essay', page: 'pascal' },
      { title: 'Leibniz to ???: on Original Sin', dateWritten: '1696 (?)', type: 'letter', page: 'originalsin' },
      { title: 'Leibniz to the Marquis l\'Hospital', dateWritten: '15/25 Jan. 1696', type: 'letter', page: 'hospital1696' },
      { title: 'Leibniz to ???: "Extract from a letter to one of my friends"', dateWritten: '3 Apr. 1696', type: 'letter', page: 'extract' },
      { title: 'Leibniz to (and from) Wilhelm Tentzel', dateWritten: 'May 1696 - Jan. 1697', type: 'letter', page: 'tentzel' },
      { title: 'Leibniz to Francis Mercury van Helmont', dateWritten: '18 October 1696', type: 'letter', page: 'vanhelmont' },
      { title: 'Leibniz to Electress Sophie', dateWritten: '4/14 November 1696', page: 'sophie1696-slt', exclude: 'yes'},
      { title: 'Sketch for a Medal Commemorating His Formula for the Quadrature of the Circle', dateWritten: 'end 1696 - beginning 1697 (?)', type: 'essay', page: 'medal' },
      { title: 'On My Early Life and Writings', dateWritten: '1697 (?)', type: 'essay', page: 'earlylife' },
      { title: 'All Things Are Full of Souls', dateWritten: 'January 1697 (?)', type: 'essay', page: 'allthings' },
      { title: 'Leibniz to Jean-Jaques Boisot', dateWritten: '10 May 1697', type: 'letter', page: 'boisot' },
      { title: 'Leibniz to André Morell', dateWritten: '31 May/10 June 1697', type: 'letter', page: 'morell1697' },
      { title: 'Leibniz to Michelangelo Fardella', dateWritten: '15 June 1697', type: 'letter', page: 'fardella' },
      { title: 'Leibniz to Electress Sophie', dateWritten: 'mid-August (?) 1697', page: 'sophie1697-slt', exclude: 'yes'},
      { title: 'Leibniz to André Morell', dateWritten: 'extract, 1/11 Oct. 1697', type: 'letter', page: 'morell1697-2' },
      { title: 'On the Ultimate Origination of Things', dateWritten: '23 Nov. 1697', type: 'essay', page: 'ultimateorigination' },
      { title: 'Leibniz to Marie de Brinon', dateWritten: '19/29 Nov. 1697', type: 'letter', page: 'brinon1697' },
      { title: 'Leibniz to Joachim Bouvet', dateWritten: '12 December 1697', type: 'letter', page: 'bouvet' },
      { title: 'Leibniz to Hermann von der Hardt', dateWritten: '21/31 Dec. 1697', type: 'letter', page: 'vonderhardt' },
      { title: 'Leibniz to Gerhard Wolter Molanus', dateWritten: '2 Feb. 1698?', type: 'letter', page: 'molanus1698' },
      { title: 'Leibniz to Johann Christian Schulenburg', dateWritten: '29 March 1698', page: 'schulenburg-slt', exclude: 'yes'},
      { title: 'Descartes on Final Causes', dateWritten: 'no later than spring 1698', type: 'essay', page: 'finalcauses' },
      { title: 'On Some Books Concerning the Apocalypse', dateWritten: 'not later than spring 1698', type: 'essay', page: 'apocalypse' },
      { title: 'On the Time of Purification', dateWritten: 'not later than spring 1698', type: 'essay', page: 'purification' },
      { title: 'Leibniz to Andre Morell', dateWritten: '4/14 May 1698', page: 'morellmay1698-slt', exclude: 'yes'},
      { title: 'Leibniz to Johann Christian Schulenburg', dateWritten: '17 May 1698', type: 'letter', page: 'schulenburg' },
      { title: 'Leibniz to Johann Bernoulli', dateWritten: 'August - September 1698', page: 'bernoulli', exclude: 'yes'},
      { title: 'Leibniz to Andre Morell', dateWritten: '29 September/9 October 1698', page: 'morellsept1698', exclude: 'yes'}, 
      { title: 'Leibniz to Nicolas Malebranche', dateWritten: '2/12 Oct. 1698', type: 'letter', page: 'malebranche1698' },
      { title: 'Leibniz to Gerhard Wolter Molanus', dateWritten: '2/12 Oct. 1698', type: 'letter', page: 'molanus1698-2' },
      { title: 'Nicolas Malebranche to Leibniz', dateWritten: '13 Dec. 1698', type: 'letter', page: 'malebranche-to-leibniz' },
      { title: 'Leibniz to Sophie', dateWritten: '8/18 Feb. 1699', type: 'letter', page: 'sophie1699' },
      { title: 'Leibniz to Ezechiel Spanheim', dateWritten: '20 Feb./2 Mar. 1699', type: 'letter', page: 'spanheim' },
      { title: 'Leibniz to Nicolas Malebranche', dateWritten: '13/23 Mar. 1699', type: 'letter', page: 'malebranche1699' },
      { title: 'Leibniz to Marie de Brinon', dateWritten: '15/25 May 1699', type: 'letter', page: 'brinon1699' },
      { title: 'Leibniz to Phillip Müller', dateWritten: '18/28 June 1699', type: 'letter', page: 'muller' },
      { title: 'Leibniz to Charles le Gobien', dateWritten: 'end June 1699 (?)', type: 'letter', page: 'gobien1699' },
      { title: 'Leibniz to Abbé Claude Nicaise', dateWritten: '6/16 Aug. 1699', type: 'letter', page: 'nicaise' },
      { title: 'Leibniz to André Morell', dateWritten: '1/11 September 1699', type: 'letter', page: 'morell1699' },
      { title: 'Leibniz to Gerhard Wolter Molanus', dateWritten: 'early Nov. 1699', type: 'letter', page: 'molanus1699' },
      { title: 'Reflections on Locke\'s', italics: ' Second Reply', dateWritten: 'end(?) 1699 - early 1700', type: 'essay', page: 'locke' },
      { title: 'An Integer is a Whole Assembled from Unities', dateWritten: 'c. 1700', type: 'essay', page: 'integer' },
      { title: 'General Mathesis', dateWritten: 'c. 1700', type: 'essay', page: 'generalmathesis' },
      { title: 'Leibniz to ???, on the Cartesian demonstration of the existence of God', dateWritten: 'after May 1700', type: 'letter', page: 'ontological' },
      { title: 'Leibniz to (and from) Gerhard Wolter Molanus', dateWritten: 'June 1700', type: 'letter', page: 'leibnizmolanus' },
      { title: 'Monads and Place', dateWritten: 'after 1700 (?)', type: 'essay', page: 'place' },
      { title: 'Demonstrations Concerning the Immense and Eternal Universe', dateWritten: '1701', type: 'essay', page: 'immenseuniverse' },
      { title: 'Summary of John Toland\'s \'Idolatries Revived Among Christians\'', dateWritten: '1701 (?)', type: 'essay', page: 'idolatriesrevived' },
      { title: 'Leibniz to Jean de Fontaney', dateWritten: 'mid-February 1701', type: 'letter', page: 'fontaney1701' },
      { title: 'Leibniz to Nathanael von Staff', dateWritten: '23 Sept. 1701', type: 'letter', page: 'nathaneal' },
      { title: 'Definitions / Axioms or Principles of Right', dateWritten: '1701 – 1705 (?)', type: 'essay', page: 'axioms' },
      { title: 'Letter to a Friend, on the Love of God', dateWritten: 'after 1701 (?)', type: 'letter', page: 'onloveofgod' },
      { title: 'Leibniz to Pierre Bayle', dateWritten: '1702?', page: 'bayle-slt', exclude: 'yes'},
      { title: 'Notes on Pierre Bayle\'s \'Dictionary\', article \'Epicurus\'', dateWritten: '1702?', type: 'essay', page: 'epicurus' },
      { title: 'Notes on Pierre Bayle\'s \'Dictionary\', article \'Origen\'', dateWritten: '1702', type: 'essay', page: 'origen' },
      { title: 'Notes on Pierre Bayle\'s \'Dictionary\', article \'Paulicians\'', dateWritten: '1702', type: 'essay', page: 'paulicians' },
      { title: 'On Salvation Outside the Visible Church', dateWritten: '1702?', type: 'essay', page: 'salvation' },
      { title: 'Leibniz to Thomas Burnett', dateWritten: '27 Feb. 1702', type: 'letter', page: 'burnett1702' },
      { title: 'Leibniz to Franz Ernst von Platen', dateWritten: '29 July 1702', type: 'letter', page: 'platen' },
      { title: 'Leibniz to (and from) Electress Sophie', dateWritten: '20 Sept. - 11 Nov. 1702', type: 'letter', page: 'sophie1702' },
      { title: 'On Unities and Transmigration', dateWritten: 'c. 1702 - 1705', type: 'essay', page: 'transmigration' },
      { title: 'Periods and Number Bases', dateWritten: '1703 (?)', type: 'essay', page: 'bases' },
      { title: 'Bernard le Bovier de Fontenelle: New Binary Arithmetic', dateWritten: '1703', type: 'essay', page: 'newbinary' },
      { title: 'Binary and the Hexagrams of Fuxi', dateWritten: 'April 1703 - end 1704', type: 'essay', page: 'fuxi' },
      { title: 'Leibniz to Bernard le Bovier de Fontenelle', dateWritten: '7 April 1703', page: 'fontenelle-slt', exclude: 'yes'},
      { title: 'Explanation of Binary Arithmetic', dateWritten: '7 April 1703', type: 'essay', page: 'binary' },
      { title: 'Leibniz to Jean-Paul Bignon', dateWritten: '7 April 1703', type: 'letter', page: 'bignon1703' },
      { title: 'Leibniz to Jean de Fontaney', dateWritten: '17 April 1703', type: 'letter', page: 'fontaney1703' },
      { title: 'Carlo Maurizio Vota to Leibniz', dateWritten: '17 April 1703', type: 'letter', page: 'vota' },
      { title: 'Casting Out Elevens', dateWritten: '14 July 1703 (?)', type: 'essay', page: 'elevens' },
      { title: 'Leibniz to Damaris Masham', dateWritten: 'Dec. 1703', type: 'letter', page: 'masham' },
      { title: 'Leibniz to Nicholaas Witsen', dateWritten: '2 March 1704', type: 'letter', page: 'witsen' },
      { title: 'Leibniz to Jean de Fontaney', dateWritten: '28 July 1704', type: 'letter', page: 'fontaney1704' },
      { title: 'Notes On a Draft of the Second Part of Isaac Jaquelot\'s', italics: ' The Conformity of Faith with Reason', dateWritten: 'September 1704 (?)', type: 'essay', page: 'jaquelot' },
      { title: 'On the Dogma of Predestination', dateWritten: 'December 1704 (?)', type: 'essay', page: 'predestination' },
      { title: 'How to Cross a Large River in View of an Enemy Army', dateWritten: 'c. 1705', type: 'essay', page: 'river' },
      { title: 'Notes on J.C.\'s', italics: ' Answer to the Query of a Deist Concerning the Necessity of Faith', dateWritten: '1705', type: 'essay', page: 'necessity' },
      { title: 'César Caze to Leibniz', dateWritten: '16 January 1705', type: 'letter', page: 'caze1705' },
      { title: 'Leibniz to Christian Wolff', dateWritten: 'selections, Feb. - Dec. 1705', type: 'letter', page: 'wolff' },
      { title: 'Leibniz to Princess Caroline of Ansbach', dateWritten: '18 March 1705', type: 'letter', page: 'caroline1705' },
      { title: 'Notes and Comments on Stephen Nye\'s', italics: ' The System of Grace and Free-Will', dateWritten: '27 Mar. 1705', type: 'essay', page: 'nye' },
      { title: 'Leibniz to Pierre de Falaiseau', dateWritten: '8 July 1705', type: 'letter', page: 'falaiseau' },
      { title: 'Leibniz to Charles le Gobien', dateWritten: '17 August 1705', type: 'letter', page: 'gobien1705' },
      { title: 'Leibniz to Ferdinand Orban', dateWritten: '27 August 1705', type: 'letter', page: 'orban' },
      { title: 'Leibniz to Johann Bernoulli', dateWritten: '30 October 1705', type: 'letter', page: 'bernoulli1705' },
      { title: 'Note on the Rocks Which Contain Dried Out Plants and Fish', dateWritten: '1706', type: 'essay', page: 'fish' },
      { title: 'On a Talking Dog', dateWritten: '1706', type: 'essay', page: 'dog' },
      { title: 'Notes on Pierre Bayle\'s \'Dictionary\', article \'Chrysippus\'', dateWritten: '1706', type: 'essay', page: 'chrysippus' },
      { title: 'Leibniz: Notes on Pierre Bayle\'s "Reply to the Questions from a Provincial"', dateWritten: 'January - February 1706', page: 'provincial', exclude: 'yes'},
      { title: 'Leibniz to Electress Sophie', dateWritten: '6 February 1706', page: 'sophie1706-slt', exclude: 'yes'},
      { title: 'Letter to Princess Caroline', dateWritten: '13 February 1706', type: 'letter', page: 'caroline1706' },
      { title: 'Leibniz to Thomas Burnett', dateWritten: '26 May 1706', type: 'letter', page: 'burnett1706' },
      { title: 'Leibniz to Pierre Coste', dateWritten: '4 July 1706', type: 'letter', page: 'coste' },
      { title: 'Leibniz to Isaac Jacquelot', dateWritten: '6 October 1706', page: 'jacquelot1706', exclude: 'yes'},
      { title: 'Leibniz to Johann Wilhelm Petersen', dateWritten: 'after 15 Oct. 1706', type: 'letter', page: 'petersen' },
      { title: 'On Book 4 of Manilius\'', italics: ' Astronomica', dateWritten: '1707 (?)', type: 'essay', page: 'manilius' },
      { title: 'Draft Preface to the', italics: ' Theodicy', dateWritten: 'early 1707?', type: 'essay', page: 'theodicy' },
      { title: 'Draft Preface to the', italics: ' Theodicy #2', dateWritten: 'early 1707?', type: 'essay', page: 'theodicy2' },
      { title: 'Letter on the Difficulties Sparked by Reason With Regard to the Compatibility of the Attributes of God with Evil', dateWritten: 'early 1707 - May 1708 (?)', type: 'letter', page: 'evil' },
      { title: 'Leibniz to Maturinus Veyssière La Croze', dateWritten: '8 Oct. 1707', type: 'letter', page: 'lacroze' },
      { title: 'Leibniz to Philippe Naudé', dateWritten: '29 Dec. 1707', type: 'letter', page: 'naude' },
      { title: 'A Dialogue', dateWritten: 'c. 1708', type: 'essay', page: 'dialogue', new: 'yes' },
      { title: 'Leibniz to Jean Le Clerc (?)', dateWritten: 'early 1708', type: 'letter', page: 'leclerc' },
      { title: 'John Toland to Leibniz', dateWritten: '6 Oct. 1708', type: 'letter', page: 'toland1708' },
      { title: 'Leibniz to Friedrich Wilhelm Bierling', dateWritten: '24 Oct. 1709', type: 'letter', page: 'bierling1709' },
      { title: 'Leibniz to John Toland', dateWritten: 'after 25 Feb. 1710', type: 'letter', page: 'toland1710' },
      { title: 'Leibniz to John Toland', dateWritten: '1 Mar. 1710', type: 'letter', page: 'toland1710-2' },
      { title: 'Leibniz to Nikolaus Hartsoeker', dateWritten: 'July/Aug. 1710', type: 'letter', page: 'hartsoeker1710' },
      { title: 'Review of Leibniz\'s', italics: ' Theodicy', dateWritten: 'November 1710', type: 'essay', page: 'theodicyreview' },
      { title: 'A Note About the Light That Some Call Aurora Borealis', dateWritten: '1710', type: 'essay', page: 'aurora' },
      { title: 'Leibniz to Friedrich Wilhelm Bierling', dateWritten: '10 Nov. 1710', type: 'letter', page: 'bierling1710' },
      { title: 'Leibniz to Charles Hugony', dateWritten: '30 November 1710', type: 'letter', page: 'hugony' },
      { title: 'Leibniz to Charles Ancillon', dateWritten: '12 December 1710', type: 'letter', page: 'ancillon' },
      { title: 'Some Remarks on My System', dateWritten: '1710 - 1714 (?)', type: 'essay', page: 'system' },
      { title: 'Leibniz to Johann Georg Liebknecht', dateWritten: '30 Jan. 1711', type: 'letter', page: 'liebknecht' },
      { title: 'Leibniz to Sebastian Kortholt', dateWritten: '22 Mar. 1711', type: 'letter', page: 'kortholt' },
      { title: 'Leibniz to Pierre Desmaizeaux', dateWritten: '8 July 1711', type: 'letter', page: 'desmaizeaux1711' },
      { title: 'Critical Remarks on Abbé Bucquoi\'s', italics: ' Discours sur l\'existence de Dieu', dateWritten: '28 Nov. 1711', type: 'essay', page: 'bucquoi' },
      { title: 'Leibniz to Nikolaus Hartsoeker', dateWritten: '7 Dec. 1711', type: 'letter', page: 'hartsoeker1711' },
      { title: 'Leibniz to Nicolas Malebranche', dateWritten: 'Jan. 1712', type: 'letter', page: 'malebranche1712' },
      { title: 'Leibniz to Friedrich Wilhelm Bierling', dateWritten: '14 Jan. 1712', type: 'letter', page: 'bierling1712' },
      { title: 'Review of Joseph Raphson\'s', italics: ' Demonstration of God', dateWritten: 'January 1712', type: 'essay', page: 'raphson' },
      { title: 'Leibniz to Pasquier Quesnel', dateWritten: '8 February 1712', type: 'letter', page: 'quesnel1' },
      { title: 'Leibniz to Christian Goldbach', dateWritten: '17 April 1712', type: 'letter', page: 'goldbach1712' },
      { title: 'What is it to be Polite?', dateWritten: 'May 1712', type: 'essay', page: 'polite' },
      { title: 'Leibniz to Jean-Leonor le Gallois de Grimarest', dateWritten: '4 June 1712', type: 'letter', page: 'grimarest' },
      { title: 'Leibniz to Louis Bourguet', dateWritten: 'October? 1712', page: 'bourguet1712', exclude: 'yes'},
      { title: 'Leibniz to Friedrich Wilhelm Bierling', dateWritten: 'Jan. - Apr. 1713', type: 'letter', page: 'bierling1713' },
      { title: 'Samuel Urlsperger to Leibniz', dateWritten: '4 Jan. 1713', type: 'letter', page: 'urlsperger' },
      { title: 'Leibniz to Christian Goldbach', dateWritten: 'after 5 January 1713', type: 'letter', page: 'goldbach1713' },
      { title: 'Leibniz to Electress Sophie', dateWritten: '4 Mar. 1713', type: 'letter', page: 'sophie1713' },
      { title: 'Leibniz to Michael Gottlieb Hansch', dateWritten: '15 March 1713', type: 'letter', page: 'hansch' },
      { title: 'Friedrich Wilhelm Bierling to Leibniz', dateWritten: '20 April 1713', type: 'letter', page: 'bierling1713-2' },
      { title: 'Review of Leibniz\'s', italics: ' Theodicy', dateWritten: 'July 1713', type: 'essay', page: 'review1713' },
      { title: 'Leibniz to Louis Bourguet', dateWritten: '22 Mar. 1714', type: 'letter', page: 'bourguet1714' },
      { title: 'Some Remarks Made While Leafing Through the Letter "A" of Bayle\'s', italics: ' Dictionary', dateWritten: 'May 1714 (?)', type: 'essay', page: 'dictionary' },
      { title: 'Leibniz to Nicolas Remond', dateWritten: 'July 1714', type: 'letter', page: 'remond1714' },
      { title: 'Restitution', dateWritten: '1715', type: 'essay', page: 'restitution' },
      { title: 'Leibniz to Abbé de St. Pierre', dateWritten: '7 Feb. 1715', type: 'letter', page: 'stpierre' },
      { title: 'George Smalridge, Bishop of Bristol to Sophie, Electress of Hanover, on Leibniz\'s', italics: ' Theodicy', dateWritten: '4 March 1715', type: 'letter', page: 'bristol' },
      { title: 'Leibniz to Caroline', dateWritten: '29 March 1715', type: 'letter', page: 'caroline' },
      { title: 'Louis Bourguet to Leibniz', dateWritten: 'Aug. 1715', type: 'letter', page: 'bourguet1715' },
      { title: 'Leibniz to Nicolas Remond', dateWritten: '4 Nov. 1715', type: 'letter', page: 'remond1715' },
      { title: 'Leibniz to Nicolas Remond', dateWritten: '6 Dec. 1715', type: 'letter', page: 'remond1715-2' },
      { title: 'Tactile Binary Clock', dateWritten: '1716 (?)', type: 'essay', page: 'binaryclock' },
      { title: 'Leibniz to Nicolas Remond', dateWritten: '17 Jan. 1716', type: 'letter', page: 'remond1716' },
      { title: 'Louis Bourguet to Leibniz', dateWritten: '7 Feb. 1716', type: 'letter', page: 'bourguet1716' },
      { title: 'Leibniz to Louis Bourguet', dateWritten: 'mid-to-late March 1716', page: 'bourguetmarch1716', exclude: 'yes'},
      { title: 'Leibniz to Louis Bourguet', dateWritten: '3 April 1716', page: 'bourguetapril1716', exclude: 'yes'},
      { title: 'Leibniz to Louis Bourguet', dateWritten: '20 Apr. 1716', type: 'letter', page: 'bourguet1716-2' },
      { title: 'Leibniz to John Arnold', dateWritten: '5 June 1716', type: 'letter', page: 'arnold' },
      { title: 'Leibniz to Louis Bourguet', dateWritten: '2 July 1716', page: 'bourguetjuly', exclude: 'yes'},
      { title: 'Leibniz to Pierre Desmaizeaux', dateWritten: '21 Aug. 1716', type: 'letter', page: 'desmaizeaux1716' },
      { title: 'Leibniz to Nicolas Remond', dateWritten: '19 Oct. 1716', type: 'letter', page: 'remond1716-2' },
      { title: 'Review of the Second Volume of', italics: ' Collection of Various Pieces on Philosophy, Natural Religion, History, Mathematics, etc., by Mr Leibniz, Mr Clarke, Mr Newton, and Other Famous Authors', dateWritten: '1721', type: 'essay', page: 'review1721' },
      { title: 'The False Minerva, Or, The Good Judgement of Momus', dateWritten: '1755 - 1757', page: 'minerva', exclude: 'yes'},
      { title: 'Explanation of a Paradox Concerning the Real Existence of the Same Body in Several Places', dateWritten: '11 November 1756', page: 'paradox', exclude: 'yes'},
      
    ];
    setTranslations(TranslationsList);
  }, []);

  const findEarliestDate = (translations) => {
    const dates = translations.map(translation => {
      const match = translation.dateWritten.match(/(\d{4})/);
      return match ? parseInt(match[1], 10) : null;
    }).filter(date => date !== null);
  
    return Math.min(...dates);
  };

  const earliestDate = findEarliestDate(translations);
  
  const essaysPapersProjects = translations.filter(translation => {return ['essay', 'paper', 'project'].includes(translation.type);});
  const letters = translations.filter(translation => translation.type === 'letter');

  const groupedEssaysPapersProjects = groupTranslationsByDecade(essaysPapersProjects, earliestDate, 10);
  const groupedLetters = groupTranslationsByDecade(letters, earliestDate, 10);
  const groupedTexts = {"All": translations.filter(translation => translation.exclude !== 'yes')};

  const [books, setBooks] = useState([]);
  const latestBook = books.filter(book => book.new === 'yes');

  function BooksDisplay() {
    return (
      <div>
        {books.map((book, index) => (
          <Row key={index} className="justify-content-center">
            <div style={{ display: 'inline-block', width: '120px', height: 'auto', paddingRight: '135px' }}>
              <Link to={book.page}>
                <img loading="lazy" src={Images[book.picture]} width="120" height="auto" alt={book.title} className="bookcover"/>
              </Link>
            </div>
            <div style={{padding: '10px 40px 20px 40px'}}>
              <Link to={book.page}><p style={{color:'black'}}>{book.title}</p></Link>
            </div>
          </Row>
        ))}
      </div>
    );
  }
     
  useEffect(() => {
    const BooksList = [
      // Put new books here at the top of this array
      { title: "LEIBNIZ ON BINARY: THE INVENTION OF COMPUTER ARITHMETIC (2022)", picture: 'binary', page: 'leibnizonbinary', new: 'yes'},
      { title: "100 AWESOME LATERAL THINKING PUZZLES (2022)", picture: 'lateral', page: 'lateral'},
      { title: "LEIBNIZ'S KEY PHILOSOPHICAL WRITINGS: A GUIDE (2020)", picture: 'key', page: 'key'},
      { title: "LEIBNIZ'S LEGACY AND IMPACT (2019)", picture: 'legacy', page: 'legacy'},
      { title: "PROOFS OF GOD IN EARLY MODERN EUROPE (2018)", picture: 'proofs', page: 'proofs'},
      { title: "THE PHILOSOPHICAL WRITINGS OF PRÉMONTVAL (2018)", picture: 'premontval', page: 'premontval'},
      { title: "TERCENTENARY ESSAYS ON THE PHILOSOPHY AND SCIENCE OF LEIBNIZ (2017)", picture: 'tercentenary', page: 'tercentenary'},
      { title: "LEIBNIZ ON GOD AND RELIGION (2016)", picture: 'godandreligion', page: 'godandreligion'},
      { title: "LEIBNIZ'S MONADOLOGY (2014)", picture: 'monadology', page: 'monadology'},
      { title: "LEIBNIZ AND THE TWO SOPHIES (2011)", picture: 'sophies', page: 'sophies'},
      { title: "SHORTER LEIBNIZ TEXTS (2006)", picture: 'slt', page: 'slt'},
      { title: "LEIBNIZ REINTERPRETED (2006)", picture: 'reinterpreted', page: 'reinterpreted'},
    ];
    setBooks(BooksList);
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Helmet>
          <title>Leibniz Translations</title>
          <meta name="keywords" content="Leibniz, Gottfried, Wilhelm, G. W. Leibniz, philosopher, translations, english translations, confessio, confessio philosophi, philosopher's confession, philosophers confession, latin, french, philosophy, theology, metaphysics, Leibnitz, Leibnizian" />
          <meta name="description" content="Welcome to leibniz-translations.com. On this site you will find English translations of various previously-untranslated papers by Gottfried Wilhelm Leibniz (1646-1716)." />
        </Helmet>
        <header>
          <div className="d-flex justify-content-center align-items-center" style={{ flexWrap: 'wrap' }}>
            <p style={{ fontFamily: 'Great Vibes', fontSize: isMobile ? '10vw' : '80px', padding: '10px 20px 0px 5px', margin: '0' }}>Leibniz Translations</p>
            <img src={Images.logo} width="auto" height= {isMobile ? '100vh' : '120vh'} alt="Logo" style={{ margin: '10px 5px 0px 10px', borderRadius: '50px' }} />
          </div>
        </header>
        <Container fluid className="d-flex justify-content-center" style={{ padding: '10px 0px 0px 0px' }}>
          <Row style={{ width: '100%', height: '60px' }}>
            <Navbar className="d-flex justify-content-center">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Nav style={{ marginRight: '10px' }}>
                <Link to='/' className='nav-home'>Home</Link>
              </Nav>
              <form className="search-container" ref={dropdownRef} onSubmit={handleSubmit}>
                <FormControl
                  id="search"
                  type="text"
                  placeholder=""
                  className="mr-sm-2"
                  style={{ marginRight: '10px', border: '1px solid black', maxHeight: '30px', maxWidth: '200px' }}
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  onFocus={() => setDropdownVisible(true)}
                />
                {dropdownVisible && filteredResults.length > 0 && (
                  <div className="search-dropdown">
                    <div style={{padding: '10px 0', borderBottom: '1px solid lightgrey'}}>
                     Click search for expanded results
                    </div>
                    {filteredResults.map((result, index) => (
                      <Link key={index} to={result.page} className="dropdown-item" onClick={handleDropdownItemClick}>
                        {result.title}<i>{result.italics}</i>
                        <br></br>
                        <div style={{color: 'grey'}}>{result.dateWritten}</div>
                      </Link>
                    ))}
                  </div>
                )}
                <Button className="SearchButton" type="submit">Search</Button>
              </form>
            </Navbar>
          </Row>
        </Container>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
                <Row className="main-content">
                  <Col id="books" md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`} style={{ padding: '20px 0px 0px 0px', borderTop: isMobile ? '1px solid lightgrey' : '' }}>
                    <TwitterFollowButton /><br></br><br></br>
                    {isMobile ? (
                      <>
                        <p>View all my books <Link to="/mobilebooks">here</Link></p>
                        <p>Extra links <Link to="/mobilelinks">here</Link></p> 
                        <p>MY LATEST BOOK (Click for details):</p>
                        {latestBook.map((book, index) => (
                          <>
                          <Link to={book.page}><img src={Images[book.picture]} width="auto" height="151" alt={book.title} style={{ marginBottom: '10px' }} /></Link>
                          <Link to={book.page}><p style={{color:'black'}}>{book.title}</p></Link>
                          </>
                        ))}
                      </>    
                    ) : (
                      <><p>MY BOOKS:</p><p>(Click for details)</p><Container>
                          <Row>
                          <BooksDisplay books />
                            <Col style={{padding: '0px 40px 0px 40px'}}>
                              <hr></hr>
                           
                              <p>This site and its contents are © Lloyd Strickland 2003-{new Date().getFullYear()} unless otherwise stated</p>
                              <p><a href="https://mmu.academia.edu/StricklandLloyd" target="_blank">Click here to see my academia.edu entry</a></p>
                              <p><a href="https://www.researchgate.net/profile/Lloyd-Strickland" target="_blank">Click here to see my ResearchGate profile</a></p>
                              <hr></hr>
                              <p>LEIBNIZ LINKS:</p>
                              <a href="http://www.mv.helsinki.fi/home/mroinila/" target="_blank">LEIBNIZ - AN UNIVERSAL PHILOSOPHER</a><br />Leibnizian resources<br /><br />
                              <a href="http://www-history.mcs.st-andrews.ac.uk/Biographies/Leibniz.html" target="_blank">GOTTFRIED WILHELM VON LEIBNIZ</a><br />Leibniz biography and weblinks<br /><br />
                              <a href="http://plato.stanford.edu/entries/leibniz-ethics" target="_blank">LEIBNIZ'S ETHICS</a><br />An article by Andrew Youpa, from the Stanford Encyclopedia of Philosophy<br /><br />
                              <a href="http://philosophyfaculty.ucsd.edu/faculty/rutherford/Leibniz/index.php" target="_blank">TEXTS AND TRANSLATIONS</a><br />Donald Rutherford's site<br /><br />

                              <a href="https://leibniz-bouvet.swarthmore.edu/" target="_blank">THE LEIBNIZ - BOUVET CORRESPONDENCE</a><br />

                              English translations (by Berkowitz and Cook) of this important correspondence<br /><br />
                              <a href="https://philpapers.org/asearch.pl?strict=1&searchStr=Leibniz,%20Gottfried%20Wilhelm&filterMode=authors" target="_blank">LEIBNIZ RESOURCES</a><br />
                              Hundreds of books and papers from philpapers<br /><br />
                              <a href="http://www.gwleibniz.com/site_links/links.html" target="_blank">LEIBNITIANA</a><br />
                              A wealth of resources in this great site by Gregory Brown<br /><br />

                              <a href="http://www.earlymoderntexts.com" target="_blank">EARLY MODERN TEXTS</a><br />Jonathan Bennett's collection of texts from early modern philosophy

                              <br /><br />

                              <a href="http://www.leibnizbrasil.pro.br" target="_blank">LEIBNIZ BRASIL</a><br />

                              Brazilian Leibniz site, with Portugese translations and Leibniz links<br /><br />

                              <a href="http://www.leibnizsociedad.org/" target="_blank">SOCIEDAD ESPA&Ntilde;OLA LEIBNIZ</a><br />Spanish Leibniz Society<br /><br />

                              <a href="http://www.philosophypages.com/ph/leib.htm" target="_blank">GOTTFRIED WILHELM LEIBNIZ - PHILOSOPHY PAGES</a><br />Leibniz information and links<br /><br />
                              <a href="https://christopherpnoble.com/" target="_blank">CHRISTOPHER NOBLE'S BLOG</a><br />
                              With posts on Leibniz and more<br /><br />

                              <a href="http://en.wikipedia.org/wiki/Gottfried_Leibniz" target="_blank">GOTTFRIED LEIBNIZ</a><br />Wikipedia entry<br /><br />

                              <a href="http://sites.northwestern.edu/germanphil/resources/" target="_blank">THE CHICAGO AREA CONSORTIUM ON GERMAN PHILOSOPHY</a><br />Links to sites on Leibniz and other German philosophers<br /><br />
                              
                            </Col>
                          </Row>
                        </Container></>
                    )}
                  </Col>
                  <Col id="translations" md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                    <p>Welcome to leibniz-translations.com. On this site you will find English translations of various papers by Gottfried Wilhelm Leibniz (1646-1716), including many not previously available in English.
                      The site is usually updated every 4 months or so (last update: October 2024). What's new:
                    </p>
                    <br></br>
                    <Row>
                      {latestTranslations.map((translation, index) => (
                        <Col key={index} xs={12} md={12} xl={4} className='custom-col' style={{marginBottom: '20px'}}>
                          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                            <Row>
                              <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '25px' }}>
                                  <span className="circle" style={{fontWeight: 'normal', fontSize: '15px'}}>new</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                  <Link to={translation.page}>
                                    <h6 className="textTitle" style={{ margin: 0 }}>{translation.title}<i>{translation.italics}</i></h6>
                                  </Link>
                                  <p style={{ color: 'grey', margin: 0, marginBottom: '17px' }}>{translation.dateWritten}</p>
                                  <hr style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 75px)', margin: 0 }}/>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <br></br>
                    <p>There are currently translations of almost 300 texts on this site. The list is given further down this page, divided into time periods of 10 years. A full list of texts is also available, listing all texts in chronological order. Alternatively, if you are searching for a specific term or text you can use the search function at the top of the page (for search strings, just type the words; don't use quotation marks). Clicking search/pressing enter will include results that find your search term within the contents of a text.</p>
                    <p>Please note: The translations presented here are original and therefore copyrighted. If passages are quoted (in essays, dissertations, theses or other works, written or otherwise) then references must be made in the proper academic way. That is, the quoted passages must be attributed to the author, and the source of the translation (i.e. this website) must be cited. No more than 5% of these translations can be reproduced elsewhere without permission of the author.</p>
                    <p>Submissions to this site are welcomed, but certain conditions apply. Please read the <Link to="/submissions">submission notes</Link> for details of these conditions.</p>
                    <p>For details of the abbreviations used throughout this site, click <Link to="/abbreviations">here.</Link></p>
                    <Row>
                      <Col>
                        <p style={{ fontFamily: 'Great Vibes', fontSize: isMobile ? '8vw' : '45px', padding: '10px 0px 20px 0px', margin: '0' }}>Translations Currently Available</p>
                      </Col>
                    </Row>
                    <span style={{ backgroundColor: 'black', color: 'white'}}><b>&nbsp;ESSAYS, PAPERS AND PROJECTS: <small>(numbered in chronological order)</small>&nbsp;</b></span><br></br><br></br>
                    <Accordion defaultActiveKey="0">
                      {renderTranslations(groupedEssaysPapersProjects, essaysAccordionRefs, setOpenEssays, openEssays)}
                    </Accordion>
                    <br></br>
                    <span style={{ backgroundColor: 'black', color: 'white'}}><b>&nbsp;LETTERS: <small>(numbered in chronological order)</small>&nbsp;</b></span><br></br><br></br>
                    <Accordion defaultActiveKey="0">
                      {renderTranslations(groupedLetters, lettersAccordionRefs, setOpenLetters, openLetters)}
                    </Accordion>
                    <br></br>
                    <span style={{ backgroundColor: 'black', color: 'white'}}><b>&nbsp;ALL TEXTS: <small>(numbered in chronological order)</small>&nbsp;</b></span> <small>(includes all essays, papers, projects and letters)</small>
                    <br></br><br></br>
                    <Accordion defaultActiveKey="0">
                      {renderTranslations(groupedTexts, textsAccordionRefs, setOpenTexts, openTexts)}
                    </Accordion>
                    <br></br>
                    <Row>
                      <p>In addition to all of the above, portions of a further 16 texts - offcuts from my anthology <var>Shorter Leibniz Texts</var> - are available by clicking <Link to="/slt">here</Link> or the picture of the front cover to the book (at the top of this page).</p>
                    </Row>            
                  </Col>
                  <Col md={1}></Col>
                </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
          {generateRoutes()}
        </Routes>
      </div>
    </Router> 
  );
}

export default App;
